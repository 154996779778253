@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  overflow: hidden;
}

.picker .react-colorful__saturation {
  border-radius: 32px 32px 0 0;
}

.picker .react-colorful__hue {
  height: 40px;
  border-radius: 0 0 32px 32px;
}